import Vue from "vue";
import Vuex from "vuex";
import UserModule from "../store/modules/user";
import CarreraModule from "../store/modules/carrera";
import TurnoModule from "../store/modules/turno";
import NewTurnoModule from "@/storeNew/modules/turno";
import AlumnoCarreraModule from "../store/modules/alumno-carrera";
import NewAlumnoCarreraModule from "../storeNew/modules/alumno-carrera";
import UCurricularModule from "@/store/modules/u-curricular";
import UCurricularCarreraModule from "@/store/modules/u-curricular-carrera";
import NewUCurricularCarreraModule from "@/storeNew/modules/u-curricular-carrera";
import CursadoModule from "@/store/modules/cursado";
import NewCursadoModule from "@/storeNew/modules/cursado";
import CampoModule from "@/store/modules/campo";
import ExamenModule from "@/store/modules/examenNew";
import AlumnoCursadoModule from "@/store/modules/alumno-cursado";
import NewAlumnoCursadoModule from "@/storeNew/modules/alumno-cursado";
import AlumnoExamenModule from "@/storeNew/modules/alumno-examen";
import CorrelativasModule from "@/store/modules/correlativas";
import NewCorrelativasModule from "@/storeNew/modules/correlativas";
import TipoCorrelativaModule from "@/store/modules/tipo-correlativa";
import IngresanteModule from "@/store/modules/ingresante";
import NewDocenteEspacio from "@/storeNew/modules/docente-espacio";
import ProvinciaModule from "@/store/modules/provincia";
import LocalidadModule from "@/store/modules/localidad";
import CorteInscripcionCarreraModule from "@/store/modules/corte-inscripcion-carrera";
import NewUserModule from "@/storeNew/modules/user";
import NewDocenteEspacioModule from "@/storeNew/modules/docente-espacio";
import NewDocenteCursadoModule from "@/storeNew/modules/docente-cursado";
import NewHorarioCursadoModule from "@/storeNew/modules/horario-cursado";
import DocenteCursadoModule from "@/storeNew/modules/docente-cursado";
import NotaAlumnoCursadoModule from "@/storeNew/modules/nota-alumno-cursado";
import NotaAlumnoExamenModule from "@/storeNew/modules/nota-alumno-examen";
import DocenteExamenModule from "@/storeNew/modules/docente-examen";
import ProgramaExamenModule from "@/storeNew/modules/programa-examen";
import PeriodoExamenModule from "@/storeNew/modules/periodo-examen";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dialogs: { delete: {} },
    loading: { status: true },
    filters: {},
    search: {},
  },
  mutations: {
    setFilters(state) {
      localStorage.setItem("filters", JSON.stringify(state.filters));
    },
    setSearch(state) {
      localStorage.setItem("search", JSON.stringify(state.search));
    },
    clearFilters(state) {
      state.filters = {};
      state.search = {};
      localStorage.removeItem("filters");
      localStorage.removeItem("search");
    },
  },
  actions: {},
  modules: {
    UserModule,
    CarreraModule,
    AlumnoCarreraModule,
    UCurricularModule,
    UCurricularCarreraModule,
    CursadoModule,
    NewCursadoModule,
    CampoModule,
    ExamenModule,
    AlumnoCursadoModule,
    AlumnoExamenModule,
    CorrelativasModule,
    TipoCorrelativaModule,
    IngresanteModule,
    NewDocenteEspacio,
    ProvinciaModule,
    LocalidadModule,
    CorteInscripcionCarreraModule,
    TurnoModule,
    NewTurnoModule,
    NewUserModule,
    NewAlumnoCarreraModule,
    NewDocenteEspacioModule,
    NewDocenteCursadoModule,
    NewUCurricularCarreraModule,
    NewHorarioCursadoModule,
    DocenteCursadoModule,
    NewCorrelativasModule,
    NewAlumnoCursadoModule,
    DocenteExamenModule,
    NotaAlumnoCursadoModule,
    NotaAlumnoExamenModule,
    ProgramaExamenModule,
    PeriodoExamenModule,
  },
});
