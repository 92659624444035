<template>
  <v-row>
    <modal-correlativo
      ref="corre"
      :cursado="cursada"
      @aceptar="addExamen"
      @cancelar="removeExamen"
    ></modal-correlativo>
    <v-col>
      <div class="d-table-row">
        <div class="d-table-cell">
          <v-checkbox
            :disabled="cursada.sinCupo"
            :class="cursada.sinCupo ? 'my-checkbox-error' : 'my-checkbox'"
            hide-details
            v-model="inscribirme"
            :label="label"
          ></v-checkbox>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ModalCorrelativo from "@/flavor/alumno/views/Inscripciones/cursado/ModalCorrelativoCursado";
import { mapActions } from "vuex";

export default {
  name: "ComisionCheckbox",
  components: { ModalCorrelativo },
  props: {
    docentes: Boolean,
    cursada: Object,
    selectedCursadas: Array,
  },
  data() {
    return {
      modal: false,
      inscribirme: false,
      model: {},
    };
  },
  computed: {
    label() {
      const horas =
        this.cursada.espacio.campo_id === 8
          ? `(${this.cursada.espacio.cant_horas} hs) `
          : "";
      const sinCupo = this.cursada.sinCupo
        ? " (Esta comisión ya ha completado su cupo)"
        : "";
      return (
        horas +
        this.cursada.espacio.nombre +
        " - " +
        this.cursada.horariosFormateados.corto +
        " (COMISIÓN " +
        this.cursada.comision +
        ") " +
        " " +
        sinCupo
      );
    },
  },
  watch: {
    selectedCursadas() {
      if (this.selectedCursadas.length === 0) {
        this.inscribirme = false;
      }
    },
    async inscribirme() {
      if (this.inscribirme) {
        if (this.docentes) {
          this.addExamen();
        } else {
          const materia = await this.getUCurricularCarrera(
            this.cursada.espacio_id
          );
          if (materia.correlativasCursado.length !== 0) {
            this.$refs.corre.modal = true;
          } else {
            this.addExamen();
          }
        }
      } else {
        this.removeExamen();
      }
    },
  },
  methods: {
    ...mapActions("UCurricularCarreraModule", ["getUCurricularCarrera"]),
    addExamen() {
      this.$emit("add", this.cursada.id);
    },
    removeExamen() {
      this.inscribirme = false;
      this.$emit("remove", this.cursada.id);
    },
  },
};
</script>

<style>
.my-checkbox-error label {
  color: rgba(255, 0, 0, 0.7) !important;
  margin-bottom: 0;

  /*background-color: red;*/
}
</style>
