<template>
  <v-dialog v-model="dialog" scrollable>
    <v-card>
      <v-card-title
        >Inscripción a Espacios Curriculares
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <div>
          <div>
            <v-select
              outlined
              hide-details
              v-model="carrera_id"
              :items="carreras"
              item-text="nombre"
              item-value="id"
            ></v-select>
          </div>
          <div v-for="(carrera, i) in porCarrera" :key="i">
            <div v-for="(anios, j) in carrera.anios" :key="i + '-' + j">
              <v-card class="mt-5" dark color="primary">
                <v-card-title> {{ j }} Año </v-card-title>
              </v-card>
              <div v-for="(cursada, h) in anios" :key="i + '-' + j + '-' + h">
                <!--            <div class="mt-5" v-if="cambioAnio(examen, i)">-->
                <!--              <v-card dark color="primary">-->
                <!--                <v-card-title> {{ cursado.espacio.anio }} Año </v-card-title>-->
                <!--              </v-card>-->
                <!--            </div>-->

                <comision-checkbox
                  :docentes="true"
                  :selected-cursadas="selectedCursadas"
                  :cursada="cursada"
                  @add="addCursada"
                  @remove="removeCursada"
                ></comision-checkbox>
              </div>
            </div>
          </div>
          <!--          <v-alert dark v-if="!porCarrera.length > 0" color="warning"-->
          <!--            >Las inscripciones se encontrarán disponibles a partir del Jueves-->
          <!--            10/03/2022 a las 00:00hs</v-alert-->
          <!--          >-->
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="inscribirCursadas" color="primary">Inscribirme</v-btn>
        <v-btn @click="cerrar" color="secondary">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapActions,
  // mapGetters,
  mapState,
} from "vuex";
import ComisionCheckbox from "@/flavor/alumno/views/Inscripciones/cursado/ComisionCheckbox";

export default {
  name: "DocenteCursadoForm",
  components: { ComisionCheckbox },
  data() {
    return {
      dialog: false,
      carrera_id: null,
      selectedCursadas: [],
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.carrera_id = this.user.carreras[0].id;
      }
      this.selectedCursadas = [];
    },
    carrera_id() {
      this.selectedCursadas = [];
      this.getCursadosActivos({
        expand: ["horarios", "espacio", "sinCupo"].toString(),
        filters: { carrera_id: this.carrera_id, estado: 1 },
        options: {
          joins: ["espacio"],
        },
      });
    },
  },
  computed: {
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("NewAlumnoCursadoModule", ["alumnoCursados"]),
    // ...mapGetters("NewCursadoModule", ["porCarrera"]),
    ...mapState("UserModule", ["user"]),
    ...mapState("NewCursadoModule", ["cursados"]),
    porCarrera() {
      const carreras = this.filteredCursados.reduce((carreras, item) => {
        if (item.espacio) {
          let tag = item.espacio.anio + "° AÑO";
          tag = item.espacio.anio;
          let tag2 = item.carrera.codigo;

          carreras[tag2] = carreras[tag2] || {};
          carreras[tag2].carrera = item.carrera;
          carreras[tag2].anios = carreras[tag2].anios || {};

          carreras[tag2].anios[tag] = carreras[tag2].anios[tag] || [];

          carreras[tag2].anios[tag].push(item);
        }
        return carreras;
      }, {});
      return carreras;
    },

    filteredCursados() {
      return this.cursados.filter((el) => !this.cursadoAlumno.includes(el.id));
    },
    cursadoAlumno() {
      return this.alumnoCursados.map((el) => el.cursado_id);
    },
  },
  methods: {
    ...mapActions("NewCursadoModule", ["getCursadosActivos", "getCursados"]),
    // ...mapActions("AlumnoCursadoModule", [
    //   "addAlumnoCursado",
    //   "getAlumnoCursados",
    //   "",
    // ]),
    ...mapActions("NewDocenteCursadoModule", [
      "addDocenteCursado",
      "getDocenteCursado",
    ]),
    addCursada(id) {
      this.selectedCursadas.push(id);
    },
    removeCursada(id) {
      var myIndex = this.selectedCursadas.indexOf(id);
      if (myIndex !== -1) {
        this.selectedCursadas.splice(myIndex, 1);
      }
    },
    async inscribirCursadas() {
      for (var i in this.selectedCursadas) {
        let model = {
          docente_id: this.user.id,
          cursado_id: this.selectedCursadas[i],
        };
        const newModel = await this.addDocenteCursado(model);
        console.log("new-model", newModel);
      }
      console.log("termine de agregar examenes");
      this.selectedCursadas = [];
      this.$emit("close");
      this.$emit("reload");
      this.dialog = false;
    },
    cerrar() {
      this.dialog = false;
      this.$emit("close");
      this.selectedCursadas = [];
    },
  },
  created() {
    // this.getAlumnoCursados({ filters: { alumno_id: this.user.id } });
    // this.getMisCursadas({
    //   expand: ["cursado", "carrera", "cursadosSuperpuestos"].toString(),
    // });
    // this.getCursados({});
    // this.carrera_id = this.user.carreras[0].id
  },
};
</script>

<style scoped></style>
